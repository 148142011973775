import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthService } from './auth.service'
import { UserService } from './user.service'

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
    constructor(
        private authService: AuthService,
        private userService: UserService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authService.isLoggedIn) {
            this.authService.userLogout(state.url)
            return false
        }

        return this.checkRoute(route)
    }

    checkRoute(route) {
        const routeHasRole = route.data && route.data.role
        if (routeHasRole) {
            const permitted = this.userService.checkUserRole(route.data.role)
            if (!permitted) {
                this.userService.navigateHome('')
                return false
            }
        }

        return true
    }
}
